<router>
{
    name: 'Home'
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols="8")
            v-card(light)
                v-card-title User Admin
                v-card-text
                    v-list(dense)
                        v-list-item(v-for="(x, idx) in cards", :key="x.title")
                            v-list-item-content SAT User # {{ idx }}
                v-card-actions
                    v-btn(color="brand", text) Manage
</template>

<script>
import dashboard from "@/components/dashboard.vue";
export default {
    extends: dashboard,
    data () {
        return {};
    },
    mounted () {
        window.dashboardvm = this;
    },
};
</script>

<style scoped>
</style>
